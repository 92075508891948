export const component_defaults = {
    /*Use custom theme*/
    cbsbins: {
        //Use global to configure default props/styling for every component in the app
        global: {
            // elevation: 10,
        },
        //Use specific component API name to configure default props/styling for all instances of this component throughout the app
        VNavigationDrawer: {
            elevation: 10,
            // style:
            // `
            //     background: ${myCustomLightTheme.colors.surface + myCustomLightTheme.defaultOpacity};
            //
            // `
        },
        VTextField: {
            variant: 'outlined',
            density: 'compact',
            color: 'primary',
            hideDetails: 'auto',
        },
        VTextarea: {
            variant: 'outlined',
            density: 'compact',
            color: 'primary',
            hideDetails: 'auto',
        },
        VSelect: {
            density: 'compact',
            variant: 'outlined',
            hideDetails: 'auto',
            persistentHint: true,
        },
        VAutocomplete: {
            variant: 'outlined',
            density: 'compact',
            color: 'primary',
            hideDetails: 'auto',
        },
        VCard: {
            elevation: 10,
            style:
                `
                    border-radius: 6px
                `,
            // class: 'pa-4'
        },
        VDataTable: {
            textEmphasis: 'high',

            // style:
            //     `
            //     background: ${myCustomLightTheme.colors.surface + myCustomLightTheme.defaultOpacity};
            //     `,
            // class: 'pa-4'
        },
        VToolbar: {
            elevation: 0,
            // style:
            //     `
            //     background: transparent;
            //     `,
            // class: 'pa-4'
        },
        VBtn: {
            style:
                `
                    border-radius: 6px
                `,
        },
        Link: {
            style: 'color: inherit'
        }

    },
    westportps: {
        global: {
            elevation: 0
        },
        VCard: {
            variant: 'outlined',
        },
        VTextField: {
            variant: 'outlined',
            density: 'compact',
            color: 'primary',
            hideDetails: 'auto',
        },
        VTextarea: {
            variant: 'outlined',
            density: 'compact',
            color: 'primary',
            hideDetails: 'auto',
        },
        VSelect: {
            density: 'compact',
            variant: 'outlined',
            hideDetails: 'auto',
            persistentHint: true,
        },
        VAutocomplete: {
            variant: 'outlined',
            density: 'compact',
            color: 'primary',
            hideDetails: 'auto',
        },
        VStepperWindow: {
            class: 'ma-0 pa-6'
        }
    }
}
