export const customThemes = {
    /*Use custom theme*/
    cbsbins: {
        dark: false,
        colors:
            {
                primary: '#1f6122',
                secondary: '#a8aaae',
                success: '#28c76f',
                info: '#00cfe8',
                warning: '#ff9f43',
                error: '#ea5455',
                'q6': '#FF8000',
                'q5':  '#80FF00',
                'q4': '#00FF80',
                'q3': '#0080FF',
                'q2': '#8000FF',
                'q1': '#FF0080',
                'cbs-red': '#7c0103',
                'cbs-green': '#1f6122'
            }
        ,
        /*modify CSS variables or add custom variables
        * all variables below will have "v-" prepended
        * */
        // variables: {
        //     'theme-on-surface': '47,43,61',
        //     'theme-on-background': '47,43,61',
        //     'border-color': '47,43,61',
        //     'border-opacity': '0.16'
        // },
    },

    myCustomLightTheme: {
        dark: false,
        colors: {
            background: '#ea5455',
            surface: '#000000',

            'theme-on-surface': '#551198',
            // surface: colors.grey.lighten2,
            primary: '#7367f0',
            'primary-darken-1': '#4839eb',
            secondary: '#a8aaae',
            'secondary-darken-1': '#74777c',
            error: '#ea5455',
            info: '#00cfe8',
            success: '#28c76f',
            warning: '#ff9f43',
            input: '#d5099c',

        },
        //set Opacity of components using the last two chars of Hex code
        // needs to manually be set on components below, using the "style" property
        // 33 = 20 opacity
        defaultOpacity: '4D',
    },
    westportps: {
        dark: false,
        colors:
            {
                background: '#ffffff',
                'on-background': 'rgb(47,43,61)',
                surface: '#fff',
                'on-surface': 'rgb(47,43,61)',
                'border-color': 'rgb(47, 43, 61)',
                primary: '#50b1b5',
                secondary: '#ABABAB',
                tertiary: '#414853',
                quaternary: '#FF8622',
                success: '#28c76f',
                'on-success': '#ffffff',
                info: '#00bad1',
                'on-info': '#ffffff',
                warning: '#E67F00',
                'on-warning': '#ffffff',
                error: '#FF6347',
                'activated-opacity': 0.16,
                'disabled-opacity': 0.4,
                'dragged-opacity': 0.1,
                'focus-opacity': 0.1,
                'high-emphasis-opacity': 0.9,
                'hover-opacity': 0.06,
                'medium-emphasis-opacity': 0.7,
                'pressed-opacity': 0.14,
            }
        ,
    }
}