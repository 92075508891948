// import 'vuetify/styles' //Vuetify Styles 
import {aliases, mdi} from "vuetify/iconsets/mdi" //Vuetify Material Icons Components
import '@mdi/font/css/materialdesignicons.css' //Vuetify Material Icon CSS
// import {md3} from 'vuetify/blueprints' //Vuetify Blueprints (see documentation)
import {createVuetify} from 'vuetify' //Create a new Vuetify Instance with this
// import 'vuetify/dist/vuetify.min.css' //I believe this gives access to modify Vuetify styles
// import {VuetifyDateAdapter} from 'vuetify/date/adapters/vuetify'

import * as components from "vuetify/components"; // Import all Vuetify components
import * as labsComponents from "vuetify/labs/components"
import { customThemes } from "./themes.js";


// Create new Veutify instance and configure options
export default createVuetify({
    components: {
        ...components,
        ...labsComponents,
    },
    //configure icons
    icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
            mdi
        }
    },
    //customise default material colour classes
    theme: {
        defaultTheme: 'light',
        variations: {
            colors: ['primary', 'secondary', 'tertiary', 'quaternary'],
            darken: 2,
            lighten: 2
        },
        themes: customThemes
    },
    //Mobile configuration
    display: {
        //Default breakpoint for mobile devices
        mobileBreakpoint: 'lg',
        //Configure the values of different thresholds
        thresholds: {
            xs: 0,
            sm: 376,
            md: 540,
            lg: 811,
            xl: 1280,
        },
    },

})
