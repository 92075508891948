// import './bootstrap';
import '../css/main.scss'; // Custom css for the app (which also imports tailwind components, etc. by default)
import {createApp, h} from 'vue';
import {createInertiaApp, Head, Link} from '@inertiajs/vue3';
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import {ZiggyVue} from '../../vendor/tightenco/ziggy/dist/index.js';
import {Ziggy} from "./ziggy";
// import DefaultLayout from "@/Layouts/DefaultLayout.vue";
import Layout from "./Layouts/Layout.vue";
import vuetify from "./plugins/vuetify";
import VueApexCharts from "vue3-apexcharts";



// Configure page titles dynamically
const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'AppName';

createInertiaApp({
    title: (title) => `${appName} - ${title}`,
    resolve: async (name) => {
        const page = await resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob([
            './Pages/**/*.vue',
            // '../fonts/**'
        ]))
        if (page.default.layout === undefined) {
            page.default.layout = Layout
        }
        return page
    },
    //Make sure spelling of app is identical in setup and createApp (not app and App)
    setup({el, App, props, plugin}) {
        const myApp = createApp({render: () => h(App, props)})
            .use(plugin)
            .use(ZiggyVue, Ziggy)
            .use(vuetify)
            .use(VueApexCharts)
            .component('Link', Link)
            .component('Head', Head)
            // .component('PageHeader', PageHeader)
        
        
        myApp
            .mount(el);

        /**
         * Remove data-page attribute from root HTML element.
         * This attribute contains all Inertia elmenents and slows down load times when lots of data
         * (like base64 images) get shared across the app.
         * This attribute is also visible on the login page if not removed.
         */
        delete el.dataset.page
        
        /**
         * Make the route helper function (named routes) available in all pages
         * The route property relies on Ziggy
         * @type {(function(*, *, *, *): String|Router)|*}
         */
        myApp.config.globalProperties.$route = route
    },
    progress: {
        color: '#0051be',
        showSpinner: 'true',
    },
});