<template>
<v-app :theme="tenantTheme" :style=" page.props.tenancy.background ? 'background: url(\'data:'+page.props.tenancy.background?.mimeType+';base64,'+page.props.tenancy.background?.content+'\')' : ''" style="height: 100vh; background-size: cover; background-repeat: no-repeat; overscroll-behavior: none; overflow: hidden">
  <Head>
    <title v-if="tenantTheme === 'cbsbins'">Business App</title>
    <title v-if="tenantTheme === 'westportps'">WestReportal</title>
    <meta
        type="description"
        content="A description should go here of what this page is"
        head-key="description"
    >
  </Head>
  
<!--  SUCCESS SNACKBAR-->
  <v-snackbar
      v-model="$page.props.flash.message"
      style=""
      color="white snack"
      class="text-center"
      width="100%"
      max-width="90vw"
  >
    <v-alert
        variant="tonal"
        color="success"
        class="text-center"
        closable
    >
      {{ $page.props.flash.message }}
      <template v-slot:close>
        <v-btn
            icon="mdi-close"
            @click="snackBar = false"
        />
      </template>
    
    </v-alert>
  </v-snackbar>
  
<!--  //ERROR SNACKBAR-->
  <v-snackbar
      v-model="$page.props.flash.error"
      style=""
      :timeout="3000"
      color="white snack"
      class="text-center"
      width="100%"
      max-width="90vw"
  >
    <v-alert
        v-if="$page.props.flash.error"
        variant="tonal"
        color="error"
        class="text-center"
        closable
    >
      {{ $page.props.flash.error }}
      <template v-slot:close>
        <v-btn
            icon="mdi-close"
            @click="snackBar = false"
        />
      </template>
    
    </v-alert>
  </v-snackbar>
  
 
  
  <v-layout>
    <v-navigation-drawer
        expand-on-hover
        :rail="!getNavDrawerStatus"
        v-model="navDrawerToggle"
        :temporary="getNavDrawerStatus"
        :class="tenantTheme === 'cbsbins' ? 'bg-cbs-green' : '' "
        >
<!--      <v-list elevation="0">
        <v-list-item elevation="0" prepend-icon="mdi-something">
          <v-switch
            class="ml-auto"
            v-model="collapseToggle"
            hide-details
            :inset="true"
            color="primary"
          />
        </v-list-item>
      </v-list>-->
      <v-list elevation="0" :nav=true density="compact">
        
        <Link href="/" style="color: inherit">
          <v-list-item rounded elevation=0 prepend-icon="mdi-home" title="Dashboard" value="dashboard" :active="$page.component === 'Dashboard/Index'" @click="getNavDrawerStatus ? navDrawerToggle = !navDrawerToggle : ''"/>
        </Link>
        
        <Link
            v-if="$page.props.tenancy.modules.includes('startup') && $page.props.auth?.user.can?.viewAnyStartup"
            href="/startup"
            style="color: inherit"
        >
          <v-list-item rounded elevation=0 prepend-icon="mdi-sun-clock" title="Startup" value="startup" :active="$page.component === 'Startup/Index'" @click="getNavDrawerStatus ? navDrawerToggle = !navDrawerToggle : ''"/>
        </Link>
        
        <Link
            v-if="$page.props.tenancy.modules.includes('bin_placement') && $page.props.auth?.user.can?.viewAnyBinPlacement"
            href="/bin-placements"
            style="color: inherit"
        >
          <v-list-item rounded elevation=0 prepend-icon="mdi-delete-restore" title="Bin Placements" value="bin-placements" :active="$page.component === 'BinPlacement/Index'" @click="getNavDrawerStatus ? navDrawerToggle = !navDrawerToggle : ''"/>
        </Link>
        
        <Link
            v-if="$page.props.tenancy.modules.includes('licence') && $page.props.auth?.user.can?.manageLicence"
            href="/licences"
            style="color: inherit"
        >
          <v-list-item rounded elevation=0 prepend-icon="mdi-card-account-details-outline" title="Licences" value="licences" :active="$page.component === 'Licence/Index'" @click="getNavDrawerStatus ? navDrawerToggle = !navDrawerToggle : ''"/>
        </Link>
        
<!--          <Link href="/stats">
            <v-list-item rounded elevation=0 prepend-icon="mdi-chart-areaspline" title="Stats" value="stats"/>
          </Link>-->
        
          <Link
              v-if="$page.props.auth?.user.can?.viewAnyUser"
              href="/users"
              style="color: inherit"
          >
            <v-list-item rounded elevation=0 prepend-icon="mdi-account" title="Users" value="users" :active="$page.component === 'Users/Index'" @click="getNavDrawerStatus ? navDrawerToggle = !navDrawerToggle : ''"/>
          </Link>
        
        <Link
            v-if="$page.props.tenancy.modules.includes('reports')"
            href="/report-templates"
            style="color: inherit"
        >
          <v-list-item rounded elevation=0 prepend-icon="mdi-clipboard-text-outline" title="Reports" value="reports" :active="$page.url.split('/').filter(item => item !== '')[0] === 'report-templates'"/>
        </Link>
        
<!--        <Link href="/incidents">
          <v-list-item rounded elevation=0 prepend-icon="mdi-shield-alert-outline" title="Incidents" value="incidents"/>
        </Link>-->
        
<!--        <Link href="/forms">
          <v-list-item rounded elevation=0 prepend-icon="mdi-form-select" title="Forms" value="forms"/>
        </Link>-->
   
      </v-list>
    </v-navigation-drawer>
    
    <v-app-bar class="bg-transparent pt-0 mt-4" elevation="0" density="compact">
      <v-card :class="tenantTheme === 'cbsbins' ? 'bg-cbs-red' : ''" class="h-100 w-100 d-flex align-center " style="justify-content: space-between">
        <v-row>
          <v-col class="text-left d-flex justify-start align-center">
            <v-btn v-if="getNavDrawerStatus" @click.stop="navDrawerToggle = !navDrawerToggle" icon="mdi-menu" class="mr-2"/>
            <img v-if="page.props.tenancy.logo" id="companylogo" class="pl-4" :class="getLogoWidth" :src="'data:'+page.props.tenancy.logo.mimeType+';base64,'+page.props.tenancy.logo.content">
          </v-col>
          <v-col class="text-right d-flex align-center justify-end pr-8">
            <span v-if="md || lg || xl" class="font-weight-bold v-card-title">
              Logged in as:
            </span>
            <span class="pa-0 v-card-text flex-grow-0">
              {{ $page.props.auth?.user.username }}
            </span>
            <Link
              href="/logout"
              method="post"
              replace
              as="button"
            >
              <v-btn
                  color="error"
                  density="comfortable"
                  variant="elevated"
                  elevation="2"
                  class="ml-4"
                  append-icon="mdi-logout"
              >
                Logout
              </v-btn>
            </Link>
          </v-col>
        </v-row>
      </v-card>
    </v-app-bar>
    
    <!--                  <v-breadcrumbs
                          :items="getItems($page.url)"
                          class="position-relative text-high-emphasis"
                          style="z-index: 1004"
                      >
                        <template v-slot:divider>
                          <v-icon icon="mdi-chevron-right"/>
                        </template>
                      
                      </v-breadcrumbs>-->
    
    <v-main class="d-flex flex-column">
      <div v-if="blurActive" class="scrollBlur"></div>
      
      <v-breadcrumbs v-if="breadCrumbs" class="pl-6 pr-6 pt-3 pb-0 text-grey">
        <template v-for="(breadCrumb, key) in breadCrumbs">
          <v-breadcrumbs-item
            :disabled="breadCrumbs.length === key+1 "
          >
            <Link :href="breadCrumb.href">{{ breadCrumb.title }}</Link>
          </v-breadcrumbs-item>
          <v-breadcrumbs-divider v-if="breadCrumbs.length !== key+1"/>
        </template>
       
      </v-breadcrumbs>
          <transition name="page" mode="out-in">
            <div :key="$page.url.split('?',  1).join()" class="pl-6 pr-6 pt-3 pb-6 main-first-child h-100 overflow-auto"  >
              
              <slot/>
            
            </div>
          </transition>
    
          <v-footer class="mr-4 ml-4 pt-20 bg-transparent text-grey-darken-4">
            © {{ new Date().getFullYear() }} Elastic Design
            <img src="/ed_logo.png" alt="" class="ml-3" style="height: 20px">
          </v-footer>
    </v-main>
  
  </v-layout>
  </v-app>

</template>

<script setup>


import {useForm, usePage} from "@inertiajs/vue3";

let snackBar = ref(true)

import {computed, provide, ref, watch} from "vue";
import {useDisplay} from "vuetify";
import vuetify from "../plugins/vuetify.js";
import {component_defaults} from "../plugins/component_defaults.js";

const tenantTheme = usePage().props.tenancy.theme

vuetify.defaults.value = component_defaults[tenantTheme]

const collapseToggle = ref(false)

let breadCrumbSlug = ref()

provide('breadCrumbSlug', breadCrumbSlug )

const blurActive = ref()

const page = usePage();
/**
 * useDisplay() gives access to the mobile breakpoints defined in vuetify.js
 * Add the threshold prop name between the {} to use it.
 * A breakpoint is 'true' if the display is bigger than that particular breakpoint, but smaller than the next breakpoint.
 */
const { mobile, xl, lg, md, sm, xs } = useDisplay();

const navDrawerToggle = ref(!mobile.value)

const form = useForm({})

watch(page, () => {
  //TODO Fix this temporary solution to only make blur active in Startup Edit
  /*scrollBur needs to be active on all but Index pages*/
  
  let isComponentIndex = usePage().component.split('/')
  // blurActive.value = true
  
  if (isComponentIndex.includes('Startup') && isComponentIndex.includes('Edit')) {
        setTimeout(() => {
          blurActive.value = true
        }, '1000')
  } else {
    blurActive.value = false
  }
  
  
  // console.log(isComponentIndex)
  // if (isComponentIndex ) {
  //   if (blurActive.value) {
  //     blurActive.value = false;
  //   }
  // } 
  //
  // if (!isComponentIndex) {
  //   if (!blurActive.value) {
  //     setTimeout(() => {
  //       blurActive.value = true
  //     }, '1000')
  //   }
  // }
})

const getNavDrawerStatus = computed(() => {
  return mobile.value;
})

const getLogoWidth = computed ( () => {
  if (md.value || xl.value || lg.value) {
    return 'md'
  }
  if (sm.value) {
    return 'sm'
  }
  if(xs.value)
  {
    return 'xs'
  }
})


// const showIfNotListComponent = computed(() => {
//   let check
// 
//   console.log(check)
//   return check
// })

  const breadCrumbs = computed(() => {
          
    let breadCrumbList = page.url.split('/').filter(item => item !== '');
    
    //Helper function to format breadCrumbs
    const formatSegment = (segment) => {
      //   Check for year/semester segment
      if (/^\d{4}-\d$/.test(segment)) {
        const [year, semester] = segment.split('-');
        return `${year} - Term ${parseInt(semester)}`
      }
      
      //Convert to Title Case and remove '-' characters
      return segment
          .split('-')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
          .join(' ');
    }
    
    //Create Breadcrumb object for v-breadcrumbs
    const breadCrumbArray = breadCrumbList.map((segment, index) => ( 
        {
      title: formatSegment(segment),
      disabled: index === breadCrumbList.length - 1,
      href: `/${breadCrumbList.slice(0, index + 1).join('/')}`
    }))
    
    
    //Make breadcrumbs user-friendly by combining titles and removing invalid url links (routes that don't exit)
    // This combinedBreadCrumbs list is currently only relevant to school reports.
    
    if (page.url.split('/').filter(item => item !== '')[0] === 'report-templates') {
      // Initialize the combined object array
      let combinedBreadCrumbs = [];
      
      // Combine keys if they exist
      if (breadCrumbArray[0]) {
        combinedBreadCrumbs.push({
          title: breadCrumbArray[0].title, // "Fish Bait"
          href: breadCrumbArray[0].href,
          disabled: null,
        });
      }
      
      if (breadCrumbArray[1]) {
        combinedBreadCrumbs.push({
          title: `${breadCrumbArray[1].title}`, // "Q2 - 2021 JF1"
          href: breadCrumbArray[1].href,
          disabled: null,
        });
      }
      
      if (breadCrumbArray[1] && breadCrumbArray[2]) {
        combinedBreadCrumbs.pop()
        combinedBreadCrumbs.push({
          title: `${breadCrumbArray[1].title} ${breadCrumbArray[2].title}`, // "Q2 - 2021 JF1"
          href: breadCrumbArray[2].href,
          disabled: null,
        });
      }
      
      if (breadCrumbArray[3]) {
        combinedBreadCrumbs.push({
          title: `${breadCrumbArray[3].title} ${breadCrumbArray[4].title}`, // "Q2 - 2021 JF1"
          href: breadCrumbArray[4].href,
          disabled: null,
        });
      }
      if (breadCrumbArray[5]) {
        combinedBreadCrumbs.push({
          title: `${formatSegment(page.props.student.first_name)} ${formatSegment(page.props.student.last_name)} - Report`, // "Isle 12398123L"
          href: breadCrumbArray[5].href,
          disabled: false,
        });
      }
      
      return combinedBreadCrumbs;
    }
    
    if (page.props.tenancy.theme === 'westportps') {
      return breadCrumbArray
    }
  })


</script>

<style lang="scss" scoped>

.v-main {
  --v-layout-top: 64px !important;
}

.v-card {
  /*max-height: 80vh;*/
  //height: max-content;
  //top: 50%;
  //transform: translateY(-50%);
  //bottom: -18px;
  margin-top: 0 !important;
  //backdrop-filter: blur(6px);
  //background-color: rgba(255,255,255,0.9);
}

@media all and (max-width: 799px ) {
  .v-card {
    /*left: 0 !important*/
  }
}

header {
  //background-color: rgba(255,255,255,0.01);
  //backdrop-filter: blur(20px);
}

.v-layout {
  height: 100%;
}

.page-enter-active,
.page-leave-active {
  transition: all 0.3s;
}

.page-enter-to {
  opacity: 1;
  scale: 1;
}

.page-leave-from {
  opacity: 1;
  scale: 1;
}

.page-enter-from {
  opacity: 0;
  scale: 0.9;
}

.page-leave-to {
  opacity: 0;
  scale: 1.1;
}

:deep(.v-snackbar__wrapper) {
  top: 0;
  bottom: unset !important;
}

:deep(.v-snackbar__content) {
  padding: 0;
    
}



header :deep(.v-toolbar__content) {
  padding-left: 24px;
  padding-right: 24px;
  overflow: visible;
    //&:after {
    //  content: '';
    //  //height: 130px;
    //  height: 65px;
    //  width: 100%;
    //  position: absolute;
    //  top: 0;
    //  left: 0;
    //  z-index: 0;
    //  -webkit-mask: linear-gradient(0deg, transparent, black 50%);
    //  backdrop-filter: blur(20px);
    //}
}

.scrollBlur {
  height: 169px;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
  -webkit-mask: linear-gradient(0deg, transparent, black 50%);
  backdrop-filter: blur(20px);
}

#companylogo.md {
  height: 50px;
}
#companylogo.sm {
  height: 40px;
}
#companylogo.xs {
  height: 35px;
}

/*
main:before{
  //content: '';
  height: 200px;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
  //background: linear-gradient(to bottom, rgba(255, 255, 255, 0.01), transparent);
  -webkit-mask: linear-gradient(0deg, transparent, black 50%);
  backdrop-filter: blur(20px);
}*/


</style>
